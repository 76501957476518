import React, { useState, useEffect } from 'react';
import {
  Container,
  Row,
  Button,
  Spinner,
  Alert,
} from 'reactstrap';
import axios from 'axios';
import uuid from '../../utils/uuid';
import { FixturePosition } from '../../config';
import { formatDate } from '../../utils/calcTimeDifference';
import MatchItem from './MatchItem';

function MatchSettingPage() {
  const [mutiMatchData, setMutiMatchData] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchError, setIsFetchError] = useState(false);

  const handleFetchData = () => {
    if (isFetching) return;
    setIsFetching(true);
    // TODO: button fetch data
    const date = formatDate(new Date());
    axios
      .post(
        `${FixturePosition}/api/GetFixtureSetting`,
        {
          Timestamp: date,
          Seq: uuid(),
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const { data } = response;
        if (data && data.ErrorCode === 0) {
          const responseMutiMatchData = data.Payload.map((setting) => ({
            periodMins: setting.PeriodMins,
            marketOpenMins: setting.MarketOpenMins,
            marketEndTimeMins: setting.MarketEndTimeMins,
            configName: setting.Config_name,
            priceChange: setting.Price_change,
            slotsLeft: setting.Slots_left,
            slotsRight: setting.Slots_right,
            strikesGap: setting.Strikes_gap,
            enabled: setting.Enabled,
          }));

          setMutiMatchData(responseMutiMatchData);
        } else {
          setIsFetchError(true);
        }
      })
      .catch((error) => {
        console.log('%c⧭ error', 'color: #00bf00', error);
        setIsFetchError(true);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  useEffect(() => {
    handleFetchData();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <div className="page-content">
          <h2>Match Setting</h2>
          {isFetchError && (
            <Row>
              <Alert color="danger">Get data error</Alert>
            </Row>
          )}
          <Row>
            <Button color="primary" onClick={handleFetchData} disabled={isFetching}>
              {!isFetching ? 'Refresh Data' : 'Loading'}
              {isFetching ? <Spinner style={{ width: '0.7rem', height: '0.7rem', marginLeft: '0.5rem' }} type="grow" color="light" /> : null}
            </Button>
          </Row>
          <Row className="mb-4">
            {mutiMatchData.map((matchData) => (
              <MatchItem key={matchData.configName} matchData={matchData} handleFetchData={handleFetchData} />
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
}

export default MatchSettingPage;
