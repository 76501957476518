import React from 'react';
import { Redirect } from 'react-router-dom';

// Authentication related pages
import Login from '../pages/Authentication/Login';
// import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Pages
import DashboardOdds from '../pages/DashboardOdds/index';
import DashboardWithVerticalRike from '../pages/DashboardOddsVertical/index';
import DashboardProbabilities from '../pages/DashboardProbabilities/index';
import TradingViewChart from '../pages/TradingViewChart/index';
import Fixtures from '../pages/Fixtures/index';
import Chart from '../pages/Chart/index';
import MatchSetting from '../pages/MatchSetting/index';

const authProtectedRoutes = [
  { path: '/dashboard-with-rake', component: DashboardOdds },
  { path: '/dashboard-odds', component: DashboardOdds },
  { path: '/dashboard-odds-vertical', component: DashboardWithVerticalRike },
  { path: '/dashboard-probabilities', component: DashboardProbabilities },
  { path: '/tv-chart', component: TradingViewChart },
  { path: '/fixtures', component: Fixtures },
  { path: '/match-setting', component: MatchSetting },
  // this route should be at the end of all other routes
  { path: '/', exact: true, component: () => <Redirect to="/login" /> },
];

const publicRoutes = [
  { path: '/login/', component: Login },
  { path: '/chart/', component: Chart },
];

export { authProtectedRoutes, publicRoutes };
