// import { SET_PRICE } from "./reducer";
import { SET_DATA } from './reducer';

export const setSocketData = (price, expiries, expTime) => (dispatch, getState) => {
  const store = getState();
  let tableData = store.tableData.data;
  // let data;
  // if(expiries===undefined)
  // {
  //     data = { ...tableData}
  // }

  if (expiries === undefined) {
    tableData = {
      ...tableData,
      price,

    };
    dispatch({ type: SET_DATA, payload: tableData });
  }

  if (!price && expiries) {
    const afterCloseOfNewMatch = tableData.expiries.filter((item) => item.id !== `${expiries[0].id}`);
    tableData = {
      ...tableData,
      expiries: afterCloseOfNewMatch,
    };
  }

  if (expiries && price && expTime) {
    tableData = {
      ...tableData,
      price,
      expiries,
      expTime,
    };
  }

  dispatch({ type: SET_DATA, payload: tableData });
};
