import React from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Card, CardBody, Container, Table, Button,
} from 'reactstrap';
import axios from 'axios';
import { numberWithCommas } from '../../utils/numberWithCommas';
import { calcTimeDifference } from '../../utils/calcTimeDifference';
import { FixturePosition } from '../../config';

function ResponsiveTable({ ...props }) {
  let expiries;
  if (props.tableData.data.expiries !== undefined) {
    expiries = props.tableData.data.expiries;
  } else {
    expiries = [];
  }
  const fixtures_dict = {};
  for (let i = 0; i < props.data.length; i++) {
    fixtures_dict[props.data[i].id] = props.data[i].endTime;
  }

  const handleCloseMatch = (fixtureID) => {
    axios
      .post(`${FixturePosition}/api/CloseFixture`, {
        FixtureId: fixtureID,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (props.getFixtures && response.data && response.data.success === true) {
          props.getFixtures();
        }
      })
      .catch((error) => {
        console.log('error', error);
      });
  };
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          {
            expiries.length !== 0
              ? expiries.map((info, index) => (
                <Col xs={12} key={index}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title text-center py-3 h2">
                        Fixture ID :
                        {info ? info.id : ''}
                        {' '}
                        - Expiry Time :
                        {new Date(fixtures_dict[info.id]).toLocaleString()}
                        {' '}
                        <span className="float-right mr-2">
                          (
                          {calcTimeDifference(Math.abs(new Date(fixtures_dict[info.id]) - new Date()))}
                          {' '}
                          to settlement)
                          {' '}
                          {' '}
                        </span>
                      </h4>
                      {/* <h4 className="card-title text-center py-3 h2">Fixture ID : {info.id}</h4> */}
                      <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                          <Table id="tech-companies-1" responsive>
                            <thead>
                              <tr>
                                <th />
                                {info && !!info.probabilities && Array.isArray(info.probabilities) && info.probabilities.length > 0 ? info.probabilities.map((proba, i) => (
                                  <React.Fragment key={i}>
                                    <th>{numberWithCommas(proba.strike)}</th>
                                  </React.Fragment>
                                )) : <th />}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><span className="over-head">OVER</span></td>
                                {info && !!info.probabilities && Array.isArray(info.probabilities) && info.probabilities.length > 0 ? info.probabilities.map((proba, i) => (
                                  <React.Fragment key={`${i}over`}>
                                    <td><span className="over ">{(proba.over).toFixed(3)}</span></td>
                                  </React.Fragment>
                                )) : <td />}
                              </tr>
                              <tr>
                                <td><span className="under-head ">UNDER</span></td>
                                {info && !!info.probabilities && Array.isArray(info.probabilities) && info.probabilities.length > 0 ? info.probabilities.map((proba, i) => (
                                  <React.Fragment key={`${i}under`}>
                                    {
                                      ((1 / (proba.under)) * (1 - props.tableData.data.rake_under)).toFixed(3) < 1
                                        ? <td><span className="under">1</span></td>
                                        : ((1 / (proba.under)) * (1 - props.tableData.data.rake_under)).toFixed(3) > 99
                                          ? <td><span className="under">99</span></td>
                                          : <td><span className="under">{proba.under.toFixed(3)}</span></td>
                                    }
                                  </React.Fragment>
                                )) : <td />}
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Button key={info.id} color="danger" size="lg" className="w-md waves-effect waves-light" type="button" onClick={() => null} onClick={() => handleCloseMatch(info.id)}>
                    Close This Match
                  </Button>
                </Col>
              ))
              : <h4>Loading data ...........</h4>
          }
        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (store) => ({
  tableData: store.tableData,
});
export default connect(mapStateToProps, {})(ResponsiveTable);
