import { combineReducers } from 'redux';

// Front
import Layout from './layout/reducer';
import tableData from './tableData/reducer';

// Authentication Module
import Account from './auth/register/reducer';
import Login from './auth/login/reducer';
import Forget from './auth/forgetpwd/reducer';

const rootReducer = combineReducers({

  // public
  Layout,
  tableData,
  // Authentication
  Account,
  Login,
  Forget,

});

export default rootReducer;
