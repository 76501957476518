import { numberWithCommas } from '../../utils/numberWithCommas';

function TableOddsVerticalRow({
  className, under, strike, over,
}) {
  const over_txt = (over)?.toFixed(3) || ' ';
  const under_txt = (under)?.toFixed(3) || ' ';

  return (
    <tr className={className}>
      <td>
        {over_txt.slice(0, -1)}
        <small>{over_txt.slice(-1)}</small>
      </td>
      <td>{numberWithCommas(strike)}</td>
      <td>
        {under_txt.slice(0, -1)}
        <small>{under_txt.slice(-1)}</small>
      </td>
    </tr>
  );
}

export default TableOddsVerticalRow;
