import React, { Component } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter, Link } from 'react-router-dom';

// i18n
import { withNamespaces } from 'react-i18next';

import { connect } from 'react-redux';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader,
} from '../../store/actions';

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');

    let matchingMenuItem = null;
    const ul = document.getElementById('side-menu');
    const items = ul.getElementsByTagName('a');
    for (let i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); // a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };

  render() {
    return (
      <div id="sidebar-menu">

        <ul className="metismenu list-unstyled" id="side-menu">
          {/* <li className="menu-title">{this.props.t('Menu')}</li> */}

          {/* <li>
                                <Link to="/dashboard" className="waves-effect">
                                    <i className="ri-dashboard-line"></i>
                                    <span className="ml-1">{this.props.t('Dashboard')}</span>
                                </Link>
                            </li>

                            <li>
                                <Link to="/dashboard-decimal-odds" className="waves-effect">
                                    <i className="ri-dashboard-line"></i>
                                    <span className="ml-1">{this.props.t('Dashboard Decimal Odds')}</span>
                                </Link>
                            </li> */}

          <li>
            <Link to="/dashboard-odds" className="waves-effect">
              <i className="ri-dashboard-line" />
              <span className="ml-1">{this.props.t('Dashboard With Odds')}</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard-odds-vertical" className="waves-effect">
              <i className="ri-dashboard-line" />
              <span className="ml-1">{this.props.t('Dashboard With Odds Vertical')}</span>
            </Link>
          </li>
          <li>
            <Link to="/dashboard-probabilities" className="waves-effect">
              <i className="ri-dashboard-line" />
              <span className="ml-1">{this.props.t('Dashboard With Probabilities')}</span>
            </Link>
          </li>
          <li>
            <Link to="/tv-chart" className="waves-effect">
              <i className="ri-dashboard-line" />
              <span className="ml-1">{this.props.t('Trading View Chart')}</span>
            </Link>
          </li>

          <li>
            <Link to="/fixtures" className="waves-effect">
              <i className="ri-calendar-2-line" />
              <span className="ml-1">{this.props.t('Fixtures')}</span>
            </Link>
          </li>
          <li>
            <Link to="/match-setting" className="waves-effect">
              <i className="ri-file-list-line" />
              <span className="ml-1">{this.props.t('Match Setting')}</span>
            </Link>
          </li>

        </ul>
      </div>
    );
  }
}

const mapStatetoProps = (state) => ({ ...state.Layout });

export default withRouter(connect(mapStatetoProps, {
  changeLayout,
  changeSidebarTheme,
  changeSidebarType,
  changeLayoutWidth,
  changePreloader,
})(withNamespaces()(SidebarContent)));
