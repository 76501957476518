import React, { useMemo } from 'react';
import {
  Row, Col, Card, CardBody, Container, Table,
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { calcTimeDifference } from '../../utils/calcTimeDifference';
import usePrevious from '../../utils/usePrevious';
import './TableOddsVertical.scss';
import TableOddsVerticalRow from './TableOddsVerticalRow';

const maxBlanks = 4;
function TableOddsVertical(props) {
  const tableData = useSelector((store) => store.tableData);
  const expiries = tableData.data.expiries ?? [];

  const info = (() => {
    const t = expiries?.at(0)?.probabilities ?? [];
    t.sort((el, el1) => el1.strike - el.strike);
    return t;
  })();

  const prevStrike = usePrevious(info.at(0)?.strike);
  const currentStrike = info.at(0)?.strike;

  const step = useMemo(() => info.at(0)?.strike - info.at(1)?.strike, [info]);

  const fixtures_dict = props.data.reduce((prev, curr) => {
    prev[curr.id] = curr.endTime;
    return prev;
  }, {});

  const forUpBlanksCount = useMemo(() => {
    const delta = currentStrike - prevStrike;
    if (delta <= -10) { return 4; }
    if (delta <= -5) { return 3; }
    if (delta <= 0) {
      return 2;
    }
    if (delta <= 5) {
      return 1;
    }

    return 0;
  }, [currentStrike, prevStrike]);

  const upperBlank = useMemo(() => {
    const result = [];
    for (let index = 0; index < forUpBlanksCount; index++) {
      result.push(
        <TableOddsVerticalRow className="dummy-row" key={`${info[0]?.strike + (index + 1) * step}dummy`} {...{ strike: info[0]?.strike + (index + 1) * step }} />,
      );
    }

    result.reverse();
    return result;
  }, [forUpBlanksCount, info, step]);

  const downBlank = useMemo(() => {
    const result = [];
    for (let index = 0; index < maxBlanks - forUpBlanksCount; index++) {
      result.push(
        <TableOddsVerticalRow className="dummy-row" key={`${info[info.length - 1]?.strike - (index + 1) * step}dummy`} {...{ strike: info[info.length - 1]?.strike - (index + 1) * step }} />,
      );
    }
    return result;
  }, [forUpBlanksCount, info, step]);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          {
            expiries.length !== 0 ?
              expiries.map((info, index) => (
                <Col sm={12} md={6}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title text-center py-3 h2">
                        Fixture ID :
                        {info.id}
                        <br />
                        Expiry Time :
                        {' '}
                        {new Date(fixtures_dict[info.id]).toLocaleString()}
                        <br />
                        (
                        {calcTimeDifference(Math.abs(new Date(fixtures_dict[info.id]) - new Date()))}
                        {' '}
                        to settlement)
                      </h4>
                      <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                          <Table id="tableOddsVertical" style={{ overflow: 'auto' }}>
                            <thead>
                              <tr>
                                <th>Over</th>
                                <th>Strike</th>
                                <th>Under</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                upperBlank
                              }
                              {info.probabilities.map((proba) => (
                                <TableOddsVerticalRow {...proba} key={proba.strike} />

                              ))}
                              {
                                downBlank
                              }

                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )) :
              <h4>Loading data ...........</h4>
          }

        </Row>
      </Container>
    </div>
  );
}

export default TableOddsVertical;
