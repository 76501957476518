import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';

import axios from 'axios';

import { FixturesUrl } from '../../config';

import TableOddsVertical from './TableOddsVertical';

function Dashboard() {
  const [data, setData] = useState([]);

  const getFixtures = () => {
    const url = FixturesUrl;

    axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((response) => {
      setData(response.data);
    }).catch((error) => {
      // console.log("error",error)
    });
  };
  useEffect(() => {
    getFixtures();
  }, []);

  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={12}>
            <TableOddsVertical data={data} />
          </Col>
          <Col xl={4} />
        </Row>
      </Container>
    </div>
  );
}

export default Dashboard;
