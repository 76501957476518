import React from 'react';
import { connect } from 'react-redux';
import {
  Row, Col, Card, CardBody, Container, Table,
} from 'reactstrap';
import { numberWithCommas } from '../../utils/numberWithCommas';
import { closestToOne } from '../../utils/closestToOne';
import { calcTimeDifference } from '../../utils/calcTimeDifference';

function ResponsiveTable(props) {
  let expiries;
  if (props.tableData.data.expiries !== undefined) { expiries = props.tableData.data.expiries; } else { expiries = []; }
  const fixtures_dict = {};
  for (let i = 0; i < props.data.length; i++) {
    fixtures_dict[props.data[i].id] = props.data[i].endTime;
  }
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          {
            expiries.length !== 0
              ? expiries.map((info, index) => (
                <Col xs={12} key={index}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title text-center py-3 h2">
                        Fixture ID :
                        {info.id}
                        {' '}
                        - Expiry Time :
                        {new Date(fixtures_dict[info.id]).toLocaleString()}
                        {' '}
                        <span className="float-right mr-2">
                          (
                          {calcTimeDifference(Math.abs(new Date(fixtures_dict[info.id]) - new Date()))}
                          {' '}
                          to settlement)
                          {' '}
                          {' '}
                        </span>
                      </h4>
                      {/* <h4 className="card-title text-center py-3 h2">Fixture ID : {info.id}</h4> */}
                      <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                          <Table id="tech-companies-1" responsive>
                            <thead>
                              <tr>
                                <th />
                                {info.probabilities.map((proba, i) => (
                                  <React.Fragment key={i}>
                                    <th>{numberWithCommas(proba.strike)}</th>
                                  </React.Fragment>
                                ))}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td><span className="over-head">OVER</span></td>
                                {info.probabilities.map((proba, i) => (
                                  <React.Fragment key={`${i}over`}>
                                    <td>
                                      <span className="over ">
                                        {

                                          closestToOne(0.95 / proba.over + 0.99 / proba.under, 0.99 / proba.over + 0.95 / proba.under) === (0.95 / proba.over + 0.99 / proba.under)
                                            ? (0.95 / proba.over).toFixed(2) : (0.99 / proba.over).toFixed(2)
                                        }
                                      </span>
                                    </td>
                                  </React.Fragment>
                                ))}
                              </tr>
                              <tr>
                                <td><span className="under-head ">UNDER</span></td>
                                {info.probabilities.map((proba, i) => (
                                  <React.Fragment key={`${i}under`}>
                                    <td>
                                      <span className="under ">
                                        {
                                          closestToOne(0.95 / proba.over + 0.99 / proba.under, 0.99 / proba.over + 0.95 / proba.under) === (0.95 / proba.over + 0.99 / proba.under) ? (0.95 / proba.under).toFixed(2) : (0.99 / proba.under).toFixed(2)

                                        }
                                      </span>
                                    </td>
                                  </React.Fragment>
                                ))}
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              ))
              : <h4>Loading data ...........</h4>
          }

        </Row>
      </Container>
    </div>
  );
}

const mapStateToProps = (store) => ({
  tableData: store.tableData,
});
export default connect(mapStateToProps, {})(ResponsiveTable);
