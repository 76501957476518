import React, { useState, useEffect } from 'react';
import {
  Container,
  InputGroup,
  InputGroupText,
  Input,
  Row,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Badge,
  Alert,
  Col,
  FormGroup,
  Label,
  Form,
} from 'reactstrap';
import axios from 'axios';
import uuid from '../../utils/uuid';
import { FixturePosition } from '../../config';
import { formatDate } from '../../utils/calcTimeDifference';
import { toNumber } from '../../utils/numberWithCommas';

function MatchItem({ matchData, handleFetchData }) {
  const [periodMins, setPeriodMins] = useState('');
  const [marketOpenMins, setMarketOpenMins] = useState('');
  const [marketEndTimeMins, setMarketEndTimeMins] = useState('');
  const [strikesGap, setStrikesGap] = useState('');
  const [enabledStatus, setEnabledStatus] = useState(false);

  const [isInputError, setIsInputError] = useState(false);
  const [modal, setModal] = useState(false);
  const [isSettingDone, setIsSettingDone] = useState(false);
  const [isSettingError, setIsSettingError] = useState(false);

  const handleSettingData = (matchData) => {
    const date = formatDate(new Date());
    const {
      configName, priceChange, slotsLeft, slotsRight,
    } = matchData;
    axios
      .post(
        `${FixturePosition}/api/SetFixtureSetting`,
        {
          Timestamp: date,
          Seq: uuid(),
          Payload: {
            Config_name: configName,
            MarketEndTimeMins: toNumber(marketEndTimeMins),
            MarketOpenMins: toNumber(marketOpenMins),
            PeriodMins: toNumber(periodMins),
            Price_change: toNumber(priceChange),
            Slots_left: toNumber(slotsLeft),
            Slots_right: toNumber(slotsRight),
            Strikes_gap: toNumber(strikesGap),
            Enabled: !!enabledStatus,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then((response) => {
        const { data } = response;
        if (data && data.ErrorCode === 0 && data.Message === 'Success') {
          handleFetchData();
          setIsSettingDone(true);
          const settingDoneAnimation = setTimeout(() => {
            setIsSettingDone(false);
            clearTimeout(settingDoneAnimation);
          }, 5000);
        }
      })
      .catch((error) => {
        console.log('%c⧭ error', 'color: #733d00', error);
        setIsSettingError(true);
      });
  };

  const checkIsNumber = (strNum) => {
    const numberRegex = /^-?\d*\.?\d+$/;
    if (numberRegex.test(strNum)) {
      return true;
    }
    return false;
  };

  const handleSendClick = () => {
    if (checkIsNumber(periodMins) && checkIsNumber(marketOpenMins) && checkIsNumber(marketEndTimeMins) && checkIsNumber(strikesGap)) {
      setIsInputError(false);
      setModal(true);
    } else {
      setIsInputError(true);
    }
  };

  const confirmSend = () => {
    handleSettingData(matchData);
    setModal(false);
  };

  const handleEnableChange = (e) => {
    setEnabledStatus(!!e.target.checked);
  };

  useEffect(() => {
    setPeriodMins(matchData.periodMins);
    setMarketOpenMins(matchData.marketOpenMins);
    setMarketEndTimeMins(matchData.marketEndTimeMins);
    setStrikesGap(matchData.strikesGap);
    setEnabledStatus(matchData.enabled);
  }, [matchData]);

  return (
    <div>
      <Table>
        <thead>
          <tr>
            <th>Type</th>
            <th>Number</th>
          </tr>
        </thead>
        <tbody>
          <tr className="table-secondary">
            <td>Market ConfigName</td>
            <td>{matchData.configName}</td>
          </tr>
          <tr className="table-info">
            <td>Period Mins</td>
            <td>{matchData.periodMins}</td>
          </tr>
          <tr className="table-secondary">
            <td>Market Open Mins</td>
            <td>{matchData.marketOpenMins}</td>
          </tr>
          <tr className="table-info">
            <td>Market End Time Mins</td>
            <td>{matchData.marketEndTimeMins}</td>
          </tr>
          <tr className="table-secondary">
            <td>Price Change</td>
            <td>{matchData.priceChange}</td>
          </tr>
          <tr className="table-info">
            <td>Slots Left</td>
            <td>{matchData.slotsLeft}</td>
          </tr>
          <tr className="table-secondary">
            <td>Slots Right</td>
            <td>{matchData.slotsRight}</td>
          </tr>
          <tr className="table-info">
            <td>Strikes Gap</td>
            <td>{matchData.strikesGap}</td>
          </tr>
          <tr className="table-secondary">
            <td>Enabled</td>
            <td>{`${matchData.enabled}`}</td>
          </tr>
        </tbody>
      </Table>
      <Row>
        <InputGroup size="lg" className="mb-3">
          <InputGroupText style={{ width: '200px' }}>Period Mins</InputGroupText>
          <Input placeholder="please type number for minutes" value={periodMins} onChange={(e) => setPeriodMins(e.target.value)} />
        </InputGroup>
        <InputGroup size="lg" className="mb-3">
          <InputGroupText style={{ width: '200px' }}>Market Open Mins</InputGroupText>
          <Input placeholder="please type number for minutes" value={marketOpenMins} onChange={(e) => setMarketOpenMins(e.target.value)} />
        </InputGroup>
        <InputGroup size="lg" className="mb-3">
          <InputGroupText style={{ width: '200px' }}>Market End Time Mins</InputGroupText>
          <Input placeholder="please type number for minutes" value={marketEndTimeMins} onChange={(e) => setMarketEndTimeMins(e.target.value)} />
        </InputGroup>
        <InputGroup size="lg" className="mb-3">
          <InputGroupText style={{ width: '200px' }}>Strikes Gap</InputGroupText>
          <Input placeholder="please type number for minutes" value={strikesGap} onChange={(e) => setStrikesGap(e.target.value)} />
        </InputGroup>
      </Row>
      <Row>
        <InputGroup size="lg" className="mb-3">
          <Form>
            <FormGroup
              check
            >
              <Input
                id={`${matchData.configName}_id`}
                type="checkbox"
                checked={enabledStatus}
                onChange={handleEnableChange}
              />
              <Label check for={`${matchData.configName}_id`}>
                Is Enabled
              </Label>
            </FormGroup>
          </Form>
        </InputGroup>
      </Row>
      <Row>
        <Col>
          <Button color="success" onClick={handleSendClick}>
            Send
          </Button>
        </Col>
        {isInputError && (
        <Col>
          <Alert color="danger">{'input can\'t not empty and only be a number'}</Alert>
        </Col>
        )}
        {isSettingError && (
        <Col>
          <Alert color="danger">Setting Error</Alert>
        </Col>
        )}
        {isSettingDone && (
        <Col>
          <Alert color="success">Setting Done</Alert>
        </Col>
        )}
      </Row>
      <Modal isOpen={modal} toggle={() => setModal(!modal)}>
        <ModalHeader toggle={() => setModal(!modal)}>Confirm Action</ModalHeader>
        <ModalBody>
          Are you sure you want to send these settings?
          <br />
          <Badge color="primary">periodMins</Badge>
          {' '}
          <Badge color="success">
            {periodMins}
          </Badge>
          <br />
          <Badge color="primary">marketOpenMins</Badge>
          {' '}
          <Badge color="success">
            {marketOpenMins}
          </Badge>
          <br />
          <Badge color="primary">marketEndTimeMins</Badge>
          {' '}
          <Badge color="success">
            {marketEndTimeMins}
          </Badge>
          <br />
          <Badge color="primary">strikesGap</Badge>
          {' '}
          <Badge color="success">
            {strikesGap}
          </Badge>
          <br />
          <Badge color="primary">enabled</Badge>
          {' '}
          <Badge color="success">
            {`${enabledStatus}`}
          </Badge>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={confirmSend}>
            Confirm
          </Button>
          {' '}
          <Button color="secondary" onClick={() => setModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default MatchItem;
