// export const FixturePosition = 'http://35.76.232.99:9000'; // SIT
export const FixturePosition = 'https://cryptobettingapp.com'; // PROD
// export const FixturesUrl = `${FixturePosition}/api/fixtures`; // SIT
// export const FixturesUrl = 'http://18.183.29.9:9000/api/fixtures';
export const FixturesUrl = `${FixturePosition}/api/fixtures`; // PORD
// export const FixturesUrl="http://nappday-stage-1717604907.ap-northeast-1.elb.amazonaws.com:9000/api/fixtures"

// export const WebSocketUrl = 'ws://35.76.232.99:8006'; // SIT
// export const WebSocketUrl = 'ws://18.183.29.9:8006';
export const WebSocketUrl = 'wss:/cryptobettingapp.com/ws'; // PROD
// export const WebSocketUrl="ws://nappday-stage-1717604907.ap-northeast-1.elb.amazonaws.com:8006"
