import React from 'react';
import { Route } from 'react-router-dom';

function AppRoute({
  component: Component,
  layout: Layout,
  isAuthProtected,
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
      // if (isAuthProtected && !localStorage.getItem("access_token")) {

      // if (isAuthProtected){
      // 	return (
      // 		<Redirect to={ { pathname: "/login", state: { from: props.location } } } />
      // 	);
      // }

        (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
    />
  );
}

export default AppRoute;
